<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8">
            <h1 class="text-warning font-weight-bold">Oops...</h1>
            <div class="alert alert-warning shadow">
              <p>
                Anda tidak diperkenankan membuka panel chat lebih dari 1 tab
                atau halaman.
              </p>
              <p>
                Silakan tutup halaman ini dan kembali ke panel chat yang sudah
                dibuka sebelumnya.
              </p>
              <p class="font-italic">- Thank you -</p>
            </div>
          </div>
          <div class="col-sm-4">
            <img :src="image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "restrict",
  data() {
    return {
      image: "/img/svg/401-police.svg",
    };
  },
  mounted() {
    this.$store.dispatch("breadcrumb", {
      title: "Restrict Area",
      path: "restrict",
    });
    this.$store.dispatch("activeLink", this.$route.path);

    let tm = this.$route.query.tm;
    let currentTime = localStorage.getItem("page_available");
    if (tm === undefined || tm !== currentTime) {
      this.$router.push("/404");
    }
  },
};
</script>

<style scoped lang="scss">
.card {
  //background-image: url("/img/svg/401-police.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}

.alert {
  p {
    text-align: center;
    font-size: 1.5em;
  }
}
</style>
